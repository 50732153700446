import React from "react";
import {
  Button,
  Flex,
  Heading,
  Box,
  SimpleGrid,
  Image,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
  Checkbox,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import countdown0 from "../images/countdown-0.png"
import countdown1 from "../images/countdown-1.png"
import countdown2 from "../images/countdown-2.png"
import countdown3 from "../images/countdown-3.png"
import countdown4 from "../images/countdown-4.png"
import countdown5 from "../images/countdown-5.png"
import dayjs from "dayjs";

const targetDate = dayjs('2025-04-03T19:00:00');

export const levels = [
  { level: 1, stage: "10", description: "10% Rabatt auf über 2.000 Artikel", start: dayjs("2025-03-24T16:00:00") },
  { level: 2, stage: "15", description: "15% Rabatt auf über 2.000 Artikel", start: dayjs("2025-03-25T16:00:00") },
  { level: 3, stage: "20", description: "20% Rabatt auf über 2.000 Artikel", start: dayjs("2025-03-26T16:00:00") },
  { level: 4, stage: "25", description: "25% Rabatt auf über 2.000 Artikel", start: dayjs("2025-03-29T16:00:00") },
  { level: 5, stage: "30", description: "30% Rabatt auf über 2.000 Artikel", start: dayjs("2025-03-31T16:00:00") },
  { level: 6, stage: "35", description: "35% Rabatt auf über 2.000 Artikel", start: dayjs("2025-04-03T19:00:00") },
  { level: 7, stage: "40", description: "???", start: dayjs("2025-04-05T16:00:00") },
  { level: 8, stage: "45", description: "???", start: dayjs("2025-04-07T16:00:00") },
  { level: 9, stage: "50", description: "???", start: dayjs("2025-04-07T16:00:00") },
  { level: 10, stage: "45", description: "???", start: dayjs("2025-04-09T16:00:00") },
]

export const Level = ({ level, description, start, children }) => {
  const active = start < dayjs()

  return <ListItem key={level.level} alignItems="center">
    <Flex alignItems={["flex-start", "center"]} direction={["column", "row"]} gap="2">
      <Flex direction="row" gap="4">
        <Checkbox colorScheme="orange" size="lg" isChecked={active} />
        <Box color="white" rounded="sm" px="1" bg={active ? "green.600" : "gray.400"} as="b">Stufe {level}</Box>
      </Flex>
      <Box fontSize="xl" fontWeight={active && "bold"}>{description}</Box>
    </Flex>
    {children}
  </ListItem>
}

export const CountdownDealBanner = () => {
  const [countdown, setCountdown] = React.useState()
  React.useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = dayjs();
      const duration = dayjs.duration(targetDate.diff(now));

      if (duration.asMilliseconds() <= 0) {
        clearInterval(timerInterval);
        console.log('Countdown finished');
      } else {
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setCountdown(({ days, hours, minutes, seconds }))
      }
    }, 1000);
  }, [])
  const bg = useColorModeValue("whiteAlpha.900", "blackAlpha.900")
  const hoverBg = useColorModeValue("whiteAlpha.200", "blackAlpha.200")
  return (
    <LinkBox>
      <Flex position="absolute" left="4" bottom="4" right="4">
        <Flex direction="column" maxW="800px" mx="auto" bg={bg} overflow="clip" rounded="md">
          <Flex direction="column" p="4" gap="1">
            <Heading as="div" size="xl">
              Countdown gestartet: Immer höhere Rabatte
            </Heading>
            <Box fontSize="xl">Unsere größte Rabattaktion, seit es Figuya gibt. Über 2000 Artikel rabattiert.</Box>
            <Box fontSize="xl">
              Doch aufgepasst: Der Rabatt steigt weiter – aber mit ihm das Risiko, dass dein Lieblingsmerch schon vergriffen ist!
              Zuschlagen oder zocken – du entscheidest!
            </Box>
            <Box fontSize="xl" fontWeight="bold">
              ✨ Unsere Rabatt-Level im Überblick: ✨
            </Box>
            <List p="4" justifyContent="flex-start" display="flex" flexDirection="column" gap="4">
              {levels.map(level => <Level key={level.level} {...level} />)}
            </List>
            <Button size="lg" colorScheme="orange" variant="outline" as={Link} to={`/de/countdown-deals`}>
              Weitere Informationen
            </Button>
            <Flex mt="4" direction={["column", "row"]} gap="2" alignItems="center" fontWeight="bold">
              <Box flex="1">
                <Box p="4">
                  Nächste Stufe
                </Box>
              </Box>
              {countdown && <Flex direction="row" gap="1">
                <Flex direction="column" alignItems="center" p="1" rounded="sm" bg="gray.600" color="white"><Box>{countdown.days}</Box> <Box color="gray.100" fontSize="xs">tag</Box></Flex>
                <Flex direction="column" alignItems="center" p="1" rounded="sm" bg="gray.600" color="white"><Box>{countdown.hours}</Box> <Box color="gray.100" fontSize="xs">std</Box></Flex>
                <Flex direction="column" alignItems="center" p="1" rounded="sm" bg="gray.600" color="white"><Box>{countdown.minutes}</Box> <Box color="gray.100" fontSize="xs">min</Box></Flex>
                <Flex direction="column" alignItems="center" p="1" rounded="sm" bg="gray.600" color="white"><Box>{countdown.seconds}</Box> <Box color="gray.100" fontSize="xs">sek</Box></Flex>
              </Flex>}
            </Flex>
          </Flex>
          <Flex direction={["column", "column", "row"]}>
            <Box _hover={{ bg: "orange.600" }} flex="1" p="4" bg="orange.500" color="orange.50" fontWeight="semibold" textAlign="center">
              <LinkOverlay as={Link} to={`/de/products?discount_stage=10&discount_stage=15&discount_stage=20&discount_stage=25&discount_stage=30&discount_stage=35&order=popularity`}>
                Zu den rabattierten Produkte
              </LinkOverlay>
            </Box>
            <Box _hover={{ bg: "red.600" }} as={Link} to={`/de/products?&discount_stage=10&discount_stage=15&discount_stage=20&discount_stage=25&discount_stage=30&discount_stage=35&order=popularity&availability=last`} flex="1" p="4" bg="red.500" color="red.50" fontWeight="semibold" textAlign="center">
              Nur letzte Exemplare anzeigen
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <SimpleGrid columns={[1, 1, 2, 3]} gap="4px">
        <Box>
          <Image src={countdown0} />
        </Box>
        <Box>
          <Image src={countdown1} />
        </Box>
        <Box>
          <Image src={countdown2} />
        </Box>
        <Box>
          <Image src={countdown3} />
        </Box>
        <Box>
          <Image src={countdown4} />
        </Box>
        <Box>
          <Image src={countdown5} />
        </Box>
      </SimpleGrid>
    </LinkBox >
  );
}

